import React, { useState, useEffect } from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';

// Query
import { useApiV2GlobalLots } from '~/hooks/apiV2/global/lot/useApiV2GlobalLots';
import { useApiV2GlobalBreeds } from '~/hooks/apiV2/global/breed/useApiV2GlobalBreeds';

import {
  Content,
  StyledTablePaper,
  StyledGridItem,
} from './styles';

import { useDebouncedPromise } from '~/utils/useDebouncedPromise';
import { useAuthContext } from '~/contexts/AuthContext';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: option => option.value,
});

const breedDefaultOption = {
  id: 'default',
  name: 'Todas',
};

const lotDefaultOption = {
  id: 'default',
  code: 'Todos',
};

export default function Filter({
  setBreedSecureId,
  setLotSecureId,
  setSearch,
  setPage,
  identificationType,
  setIdentificationType,
  lot_id = null
}) {
  const { farm } = useAuthContext();
  const { powerUps } = farm;

  const [lots, setLots] = useState([]);
  const [breeds, setBreeds] = useState([]);
  const identifiers = [
    { label: 'Sisbov', value: 'sisbov' },
    { label: 'Brinco', value: 'ident_earing' },
    { label: 'botton RFID', value: 'botton_rfid' },
  ]

  const debouncedChange = useDebouncedPromise(HandleSearch, 500);

  const { data: lotsData } = useApiV2GlobalLots([true]);
  const { data: breedsData } = useApiV2GlobalBreeds();

  function HandleSearch(value) {
    if (value.length === 0) {
      setSearch('');
    }
    setPage(0);
    setSearch(value.replace(/\s/g, '%'));
  }

  function handleSelectLot(event) {
    if (event.target.value === 'default') {
      setLotSecureId(null);
    } else if (event.target.value) {
      setLotSecureId(event.target.value);
    }
  }

  function handleChangeBreed(event) {
    if (event.target.value === 'default') {
      setBreedSecureId(null);
    } else if (event.target.value) {
      setBreedSecureId(event.target.value);
    }
  }

  useEffect(() => {
    if (lotsData) {
      setLots(lotsData.lots);
    }
  }, [lotsData]);

  useEffect(() => {
    if (breedsData) {
      setBreeds(breedsData.breeds);
    }
  }, [breedsData]);

  useEffect(() => {
    if (lot_id) {
      setLotSecureId(lot_id);
    }
  }, []);

  return (
    <Content>
      <StyledTablePaper>
        <Grid container justify="space-around" spacing={2}>
          {(!powerUps || powerUps.length <= 0) && (
            <StyledGridItem item xs={12} sm>
              <Autocomplete
                name="lot"
                size="small"
                style={{ height: 38, width: '100%' }}
                options={identifiers}
                noOptionsText="Sem opções"
                filterOptions={filterOptions}
                getOptionLabel={option => option.label}
                onChange={(e, value) => {
                  value ? setIdentificationType(value.value) : setIdentificationType('')
                }}
                renderInput={params => (
                  <TextField {...params} label="Pesquisar por" fullWidth />
                )}
              />
            </StyledGridItem>
          )}
          <StyledGridItem item xs={12} sm>
            <TextField
              name="search"
              variant="standard"
              label="Pesquisar"
              autoComplete="nope"
              autoFocus
              fullWidth
              disabled={(!powerUps || powerUps.length <= 0) && !identificationType}
              size="small"
              onChange={event => {
                debouncedChange(event.target.value)
              }}
            />
          </StyledGridItem>
          <StyledGridItem item xs={12} sm>
            <FormControl size="small" fullWidth>
              <InputLabel id="input-diet">Lote</InputLabel>
              <Select
                id="lot"
                defaultValue={lot_id ? lot_id : lotDefaultOption.id}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                onChange={handleSelectLot}
              >
                <MenuItem value={lotDefaultOption.id}>
                  {lotDefaultOption.code}
                </MenuItem>
                {lots.map(item => (
                  <MenuItem key={item.secure_id} value={item.secure_id}>
                    {item.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </StyledGridItem>
          <StyledGridItem item xs={12} sm>
            <FormControl size="small" fullWidth>
              <InputLabel id="input-diet">Raça</InputLabel>
              <Select
                id="diet"
                defaultValue={breedDefaultOption.id}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                onChange={handleChangeBreed}
              >
                <MenuItem value={breedDefaultOption.id}>
                  {breedDefaultOption.name}
                </MenuItem>
                {breeds.map(item => (
                  <MenuItem key={item.secure_id} value={item.secure_id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </StyledGridItem>
        </Grid>
      </StyledTablePaper>
    </Content>
  );
}

Filter.defaultProps = {
  currentDate: null,
};

Filter.propTypes = {
  lot_id: PropTypes.number,
  setBreedSecureId: PropTypes.func.isRequired,
  setLotSecureId: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  identificationType: PropTypes.string,
  setIdentificationType: PropTypes.func.isRequired,
};
