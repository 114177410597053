import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Formik, Form, ErrorMessage } from 'formik';
import { uuid } from 'uuidv4';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import {
	TextField,
	Grid,
	TableContainer,
	TableRow,
	TableCell,
	TableBody,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	Checkbox,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import AutorenewIcon from '@material-ui/icons/Autorenew';

// Query
import { useApiV2GlobalFood } from '~/hooks/apiV2/global/food/useApiV2GlobalFood';
import { useApiV2AddDiet } from '~/hooks/apiV2/register/diet/useApiV2AddDiet';

import {
	Container,
	StyledPaper,
	ContentItem,
	ContentSubmitButtons,
	StyledSubmitButton,
	StyledGridItem,
	StyledTable,
	StyledTableHead,
	StyledButtonMix,
	FoodName,
	StyledGridFood,
	StyledIconButton,
	StyledButton,
	StyledDivider,
	StyledTableCell,
	TitleTable,
	StyledButtonMixer,
	Error,
	TableCellStyled,
} from './styles';

import ModalMix from '../Components/ModalMix';
import ModalMixFoods from '../Components/ModalMixFoods';

import { calcPercentMsDiet } from '~/utils/calc';
import { formatNumber } from '~/utils/format';
import history from '~/services/history';
import colors from '~/styles/colors';
import Loader from '~/components/Loader';
import ButtonTable from '~/components/ButtonTable';
import ButtonSubmit from '~/components/ButtonSubmit';
import ModalFood from '~/components/ModalFood';
import NumberFormatForm from '~/components/NumberFormatForm';
import NumberFormatPercent from '~/components/NumberFormatPercent';
import Can from '~/components/Can';
import apiV2 from '~/services/apiV2';
import { queryClient } from '~/services/queryClient';
import { ButtonsChangeOrder } from '~/components/ButtonsChangeOrder';

const profiles = [
	"Adaptação",
	"Crescimento",
	"Terminação",
	"Recria",
	"Manutenção"
]
export default function Register({ location, closeModal }) { // eslint-disable-line
	const [listFood, setListFood] = useState([]);
	const [totalInclMS, setTotalInclMS] = useState(0);
	const [totalInclMO, setTotalInclMO] = useState(0);
	const [lackOfTotal, setlackOfTotal] = useState(100);
	const [percentMSDiet, setPercentMSDiet] = useState(0);
	const [messageErroDiet, setMessageErroDiet] = useState('');
	const [messageErro, setMessageErro] = useState('');
	const [currentFood, setCurrentFood] = useState({});
	const [propPercentMsFood, setPropPercentMsFood] = useState(null);
	const [visibleModalFood, setVisibleModalFood] = useState(false);
	const [countFoods, setCountFoods] = useState(0);
	const [visibleModalMix, setVisibleModalMix] = useState(false);
	const [visibleModalMixFoods, setVisibleModalMixFoods] = useState(false);
	const [currentMixFood, setCurrentMixFood] = useState({});
	const [messageErroFood, setMessageErroFood] = useState('');

	const routeName = useMemo(() => {
		const splitRoute = location.pathname.split('/'); // eslint-disable-line
		return splitRoute[splitRoute.length - 2];
	}, [location.pathname]); // eslint-disable-line

	// Query
	const { data: foodsData } = useApiV2GlobalFood([true], 'register', null);
	const addDiet = useApiV2AddDiet(closeModal);

	const schema = Yup.object().shape({
		name: Yup.string().required('Informe o nome da dieta'),
		days_consumption: Yup.number()
			.typeError('Digite um número válido!')
			.integer('Número deve ser inteiro')
			.required('Necessário informar os dias'),
		percent_pv_first_treaty: Yup.number()
			.typeError('Digite um número válido!')
			.min(0.1, 'Necessário informa um número maior que 0')
			.required('Necessário informar a porcentagem'),
		percent_pv_target: Yup.number()
			.typeError('Digite um número válido!')
			.required('Necessário informar a porcentagem'),
		gmd_projected: Yup.number()
			.typeError('Digite um número válido!')
			.required('Necessário informar a porcentagem'),
		profile: Yup.string()
	});

	function calcStep1(percent_incl_ms_food, percent_ms) {
		const step1 = percent_incl_ms_food * 100;
		const result = step1 / percent_ms;
		return Number(result);
	}

	function calcStep2(calc_step_1, total_mo) {
		let step2 = 0;

		if (total_mo === 0) {
			step2 = calc_step_1 / calc_step_1;
		} else {
			step2 = calc_step_1 / total_mo;
		}

		const result = step2 * 100;
		return Number(result);
	}

	function hasFoodSelected({ secure_id, selected }) {
		const sameFoods = listFood.filter(food => food.secure_id === secure_id);

		if (sameFoods.find(food => food.selected)) {
			return !selected;
		}

		return false;
	}

	function hasSameFood() {
		const countGroupByFoodId = listFood.reduce(
			(accumulador, food) =>
				accumulador.some(newFood => newFood.secure_id === food.secure_id)
					? accumulador
					: accumulador.concat(food),
			[]
		).length;

		return countGroupByFoodId < listFood.length;
	}

	function handleModalFood() {
		setVisibleModalFood(!visibleModalFood);
	}

	function handleModalMix() {
		setVisibleModalMix(!visibleModalMix);
		setMessageErroFood('');
	}

	function handleModalMixFoods(food) {
		setVisibleModalMixFoods(!visibleModalMixFoods);
		setCurrentMixFood(food);
	}

	function handleSelectAllFoods() {
		const newListFood = listFood.map(item => ({
			...item,
			selected: listFood.length !== countFoods,
		}));

		setListFood(newListFood);
		setCountFoods(newListFood.filter(item => item.selected === true).length);
	}

	function handleRollbackMix() {
		if (currentMixFood && currentMixFood.type === 'mix') {
			const oldListFood = listFood.filter(food => food !== currentMixFood);
			const foodsPremix = currentMixFood.preMixes.map(mix => ({
				...mix.food,
				unique_key: uuid(),
				secure_id: mix.food.secure_id,
				selected: false,
				dietFoodPivot: {
					percent_incl_ms_food:
						(mix.percent_incl_ms_food *
							currentMixFood.dietFoodPivot.percent_incl_ms_food) /
						100,
					percent_incl_mo_food:
						(mix.percent_incl_mo_food *
							currentMixFood.dietFoodPivot.percent_incl_mo_food) /
						100,
				},
			}));

			const data = [...oldListFood, ...foodsPremix]
			const result = [...new Set(data.map(x => x.secure_id))].map((y, index) => {
				const foodFilter = data.filter(z => z.secure_id === y)
				const { percent_incl_ms_food, percent_incl_mo_food } = foodFilter.reduce(
					(accumulator, food) => {
						accumulator.percent_incl_ms_food += Number(food.dietFoodPivot.percent_incl_ms_food);
						accumulator.percent_incl_mo_food += Number(food.dietFoodPivot.percent_incl_mo_food);

						return accumulator;
					},
					{
						percent_incl_ms_food: 0,
						percent_incl_mo_food: 0,
					}
				);

				const calc_step_1 = calcStep1(percent_incl_ms_food, foodFilter[0].percent_ms);

				return {
					...foodFilter[0],
					calc_step_1,
					dietFoodPivot: {
						percent_incl_ms_food,
						percent_incl_mo_food,
						order: index
					}
				}
			})

			setListFood(result);
			handleModalMixFoods();
		}
	}

	function handleSendFoodToList() {
		if (!propPercentMsFood) {
			setMessageErro('Informe um valor válido!');
			return;
		}

		const existFood = listFood.find(
			dietFood => dietFood.secure_id === currentFood.secure_id
		);

		if (existFood) {
			setMessageErro('Alimento já inserido na dieta!');
			return;
		}

		if (Object.values(currentFood).length === 0) {
			setMessageErro('Nenhum alimento foi selecionado !');
			return;
		}

		const inclMS = Number(propPercentMsFood);

		if (inclMS > 100) {
			setMessageErro('A porcentagem máxima do alimento é 100%');
			return;
		}

		if (inclMS < 0) {
			setMessageErro('A porcentagem mínima do alimento é 0%');
			return;
		}

		const listTotalInclMS = listFood.reduce(
			(total, food) =>
				parseFloat(total.toFixed(4)) +
				parseFloat(food.dietFoodPivot.percent_incl_ms_food.toFixed(4)),
			0
		);

		const newTotalInclMS =
			parseFloat(listTotalInclMS.toFixed(4)) + parseFloat(inclMS.toFixed(4));

		if (newTotalInclMS > 100) {
			setMessageErro('A soma de Incl. MS é maior que 100%');
			return;
		}
		// Conditional to add value to variable
		if (newTotalInclMS > 0) {
			const newTotalInclMSRegex = `${newTotalInclMS}e${4}`
			const lack_OfTotal = Number(
				`${Math.round(newTotalInclMSRegex)}e-${4}`
			);
			// `${Math.round(`${newTotalInclMS}e${4}`)}e-${4}`
			setlackOfTotal(lack_OfTotal - 100);
		}

		// Calc the new object
		const calc_step_1 = calcStep1(inclMS, currentFood.percent_ms);

		let total_mo = 0;

		listFood.map((food, index) => {
			if (index > 0) {
				total_mo = food.calc_step_1 + total_mo;
			} else {
				total_mo = food.calc_step_1 + calc_step_1;
			}

			return null;
		});

		const inclMO = calcStep2(calc_step_1, total_mo);

		const newDataFood = {
			...currentFood,
			unique_key: uuid(),
			calc_step_1,
			selected: false,
			dietFoodPivot: {
				percent_incl_ms_food: inclMS,
				percent_incl_mo_food: inclMO,
				order: listFood.length
			},
		};

		// Calc the new percent MO, with the new total MO for old foods in the list
		const alterDataFood = listFood.map((food, index) => ({
			...food,
			dietFoodPivot: {
				percent_incl_ms_food: food.dietFoodPivot.percent_incl_ms_food,
				percent_incl_mo_food: calcStep2(food.calc_step_1, total_mo),
				order: index
			},
		}));

		setListFood([...alterDataFood, newDataFood]);

		setPropPercentMsFood(null);
		setMessageErro('');
		setCurrentFood({});
	}

	function handleRemoveFoodToList(index) {
		const newlistFood = [...listFood];

		newlistFood.splice(index, 1);

		// Sum the new total MO
		const total_mo = newlistFood.reduce(
			(total, food) => total + Number(food.calc_step_1),
			0
		);

		// Alter all porcents of MO old with the new total MO
		const alterDataFood = newlistFood.map((food, newIndex) => ({
			...food,
			dietFoodPivot: {
				percent_incl_ms_food: food.dietFoodPivot.percent_incl_ms_food,
				percent_incl_mo_food: calcStep2(food.calc_step_1, total_mo),
				order: newIndex
			},
		}));

		// Sum the new lack total
		const listTotalInclMS = newlistFood.reduce(
			(total, food) => total + Number(food.dietFoodPivot.percent_incl_ms_food),
			0
		);

		const newLackValue = listTotalInclMS - 100;

		setListFood(alterDataFood);
		setlackOfTotal(newLackValue);
	}

	function handleSelectedFood(food, index) {
		const { selected } = food;

		const newFood = { ...food, selected: !selected };

		const newListFood = [...listFood];

		newListFood.splice(index, 1, newFood);

		setListFood(newListFood);
		setCountFoods(newListFood.filter(item => item.selected === true).length);
	}

	async function handleSubmit(data) {
		const currentDietDataFoods = listFood.map(food => ({
			foodSecureId: food.secure_id,
			percentInclMsFood: food.dietFoodPivot.percent_incl_ms_food,
			percentInclMoFood: food.dietFoodPivot.percent_incl_mo_food,
			order: food.dietFoodPivot.order
		}));

		const newData = {
			name: data.name,
			profile: data.profile,
			percentPvFirstTreaty: data.percent_pv_first_treaty,
			percentPvTarget: data.percent_pv_target,
			gmdProjected: data.gmd_projected,
			daysConsumption: data.days_consumption,
			percentMsDiet: Number(percentMSDiet),
			foods: currentDietDataFoods,
		};

		setMessageErroDiet('');

		await addDiet.mutateAsync(newData);
	}

	async function generateNewMix(name) {
		const mixFoods = listFood.filter(food => food.selected === true).map(foodFiltered => ({
			calcStep1: foodFiltered.calc_step_1,
			dietFoodPivot: {
				percentInclMoFood: foodFiltered.dietFoodPivot.percent_incl_mo_food,
				percentInclMsFood: foodFiltered.dietFoodPivot.percent_incl_ms_food,
			},
			name: foodFiltered.name,
			percentMs: foodFiltered.percent_ms,
			secureId: foodFiltered.secure_id,
			selected: foodFiltered.selected,
			uniqueKey: foodFiltered.unique_key,
		}));
		const oldListFood = listFood.filter(food => food.selected === false).map((foodFiltered, index) => ({
			...foodFiltered,
			dietFoodPivot: {
				...foodFiltered.dietFoodPivot,
				order: index
			}
		}));

		try {
			const { data } = await apiV2.post('/v1/feedlot/register/premix', {
				name,
				mixFoods,
				newLastOrder: oldListFood.length
			})

			setListFood([...oldListFood, data]);
			setVisibleModalMix(false);
			setCountFoods(0);
			setMessageErroFood('');
			queryClient.invalidateQueries();
		} catch (error) {
			const type = error.response.data.type ? error.response.data.type : 'error'
			const message = error.response.data.message ? error.response.data.message : 'Erro ao cadastrar pré mistura!'
			toast[type](message)
		}
	}

	async function handleGenerateMix({ name }) {
		setVisibleModalMix(false);
		Swal.fire({
			position: 'top',
			title: 'Você tem certeza?',
			text:
				'Será gerado uma nova pré mistura! Após o cadastro não será possível a edição destes alimentos na pré mistura, deseja continuar?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: colors.lightPrimary,
			cancelButtonColor: colors.grey,
			confirmButtonText: 'Ok, cadastrar pré mistura!',
		}).then(async result => {
			if (result.value) {
				// Create Mix
				generateNewMix(name);
			}
		});
	}

	useEffect(() => {
		const { inclMS, inclMO } = listFood.reduce(
			(accumulator, food) => {
				accumulator.inclMS += Number(food.dietFoodPivot.percent_incl_ms_food);
				accumulator.inclMO += Number(food.dietFoodPivot.percent_incl_mo_food);

				return accumulator;
			},
			{
				inclMS: 0,
				inclMO: 0,
			}
		);

		setTotalInclMS(inclMS);
		setTotalInclMO(Math.round(inclMO));
		// setTotalPriceTon(priceTon);
	}, [listFood]);

	useEffect(() => {
		// Calc PercentMSDiet
		if (listFood.length > 0) {
			const totalMSDiet = calcPercentMsDiet(listFood);

			setPercentMSDiet(totalMSDiet);
		} else {
			setPercentMSDiet(0);
		}
	}, [listFood, totalInclMS]);

	function handleBack() {
		history.push('/dashboard/diet');
	}

	return (
		<center>
			{false ? (
				<Loader />
			) : (
				<Container>
					<Formik
						validationSchema={schema}
						initialValues={{
							name: '',
							days_consumption: '',
							percent_pv_first_treaty: '',
							percent_pv_target: '',
							gmd_projected: '',
							profile: ''
						}}
						onSubmit={handleSubmit}
					>
						{({
							handleChange,
							setFieldValue,
							handleBlur,
							errors,
							touched,
							values,
							isSubmitting
						}) => (
							<Form>
								<ContentItem>
									<StyledPaper>
										<Grid container justify="space-around" spacing={2}>
											<StyledGridItem item xs={12} sm>
												<TextField
													name="name"
													variant="outlined"
													label="Dieta"
													placeholder="Digite o nome da dieta"
													autoComplete="nope"
													size="small"
													autoFocus
													inputProps={{
														form: {
															autocomplete: 'off',
														},
													}}
													fullWidth
													disabled={routeName === 'view'}
													value={values.name}
													onChange={handleChange}
													error={
														(!!errors.name && touched.name) ||
														messageErroDiet !== ''
													}
												/>
												{messageErroDiet !== '' ? (
													<Error>{messageErroDiet}</Error>
												) : (
													<ErrorMessage name="name" component={Error} />
												)}
											</StyledGridItem>

											<StyledGridItem item xs={12} sm>
												<FormControl
													variant="outlined"
													size="small"
												>
													<InputLabel id="profile-label">
														Perfil
													</InputLabel>
													<Select
														labelId="profile-label"
														labelWidth={35}
														id="profile-input"
														name="profile"
														size="small"
														value={values.profile}
														onChange={handleChange}
														error={
															(!!errors.profile && touched.profile)
														}
													>
														{profiles &&
															profiles.map(profile => (
																<MenuItem
																	key={profile}
																	value={profile}
																	size="small"
																>
																	{profile}
																</MenuItem>
															))}
													</Select>
													<ErrorMessage name="profile" component={Error} />
												</FormControl>
											</StyledGridItem>

											<StyledGridItem item xs={12} sm="auto">
												<Can checkRole={['administrator', 'owner', 'beefer']}>
													<NumberFormatForm
														name="days_consumption"
														label="Dias de consumo"
														placeholder="0"
														decimalScale={0}
														allowNegative={false}
														value={values.days_consumption}
														onBlur={handleBlur}
														setFieldValue={setFieldValue}
														error={
															!!errors.days_consumption &&
															touched.days_consumption
														}
													/>
												</Can>
												<ErrorMessage
													name="days_consumption"
													component={Error}
												/>
											</StyledGridItem>
										</Grid>

										<Grid container justify="space-around" spacing={2}>
											<StyledGridItem item xs={12} sm>
												<Can checkRole={['administrator', 'owner', 'beefer']}>
													<NumberFormatPercent
														name="percent_pv_first_treaty"
														label="Primeiro Trato PV (%)"
														placeholder="0,00"
														decimalScale={2}
														allowNegative={false}
														value={values.percent_pv_first_treaty}
														onBlur={handleBlur}
														setFieldValue={setFieldValue}
														error={
															!!errors.percent_pv_first_treaty &&
															touched.percent_pv_first_treaty
														}
													/>
												</Can>
												<ErrorMessage
													name="percent_pv_first_treaty"
													component={Error}
												/>
											</StyledGridItem>
											<StyledGridItem item xs={12} sm>
												<Can checkRole={['administrator', 'owner', 'beefer']}>
													<NumberFormatPercent
														name="percent_pv_target"
														label="Meta PV (%)"
														placeholder="0,00"
														decimalScale={2}
														allowNegative={false}
														value={values.percent_pv_target}
														onBlur={handleBlur}
														setFieldValue={setFieldValue}
														error={
															!!errors.percent_pv_target &&
															touched.percent_pv_target
														}
													/>
												</Can>
												<ErrorMessage
													name="percent_pv_target"
													component={Error}
												/>
											</StyledGridItem>
											<StyledGridItem item xs={12} sm>
												<Can checkRole={['administrator', 'owner', 'beefer']}>
													<NumberFormatForm
														name="gmd_projected"
														label="GMD projetado (EM KILOS)"
														autoComplete="nope"
														placeholder="0,0000"
														decimalScale={4}
														allowNegative={false}
														value={values.gmd_projected}
														onBlur={handleBlur}
														setFieldValue={setFieldValue}
														error={
															!!errors.gmd_projected && touched.gmd_projected
														}
													/>
												</Can>
												<ErrorMessage name="gmd_projected" component={Error} />
											</StyledGridItem>
										</Grid>

										<StyledDivider light />

										<StyledGridFood
											container
											direction="row"
											spacing={2}
											justify="space-around"
										>
											<Can checkRole={['administrator', 'owner', 'beefer']}>
												<Grid item sm xs={9}>
													<FormControl
														variant="outlined"
														size="small"
														fullWidth
													>
														<InputLabel id="food-label">
															Alimento
														</InputLabel>
														<Select
															labelId="food-label"
															labelWidth={65}
															id="food-input"
															name="food"
															size="small"
															value={currentFood}
															onChange={event =>
																setCurrentFood(event.target.value)
															}
														>
															{foodsData &&
																foodsData.map(food => (
																	<MenuItem
																		key={food.unique_key}
																		value={food}
																		size="small"
																	>
																		{food.name}
																	</MenuItem>
																))}
														</Select>
													</FormControl>
												</Grid>

												<Grid item sm="auto" xs="auto">
													<StyledIconButton
														variant="contained"
														size="small"
														onClick={handleModalFood}
													>
														<AddIcon />
													</StyledIconButton>
												</Grid>

												<Grid item sm xs={7}>
													<NumberFormatPercent
														label="Proporção MS (%)"
														placeholder="0,0000"
														decimalScale={4}
														allowNegative={false}
														value={propPercentMsFood}
														onBlur={handleBlur}
														onValueChange={val =>
															setPropPercentMsFood(val.floatValue)
														}
														error={
															(!!errors.percent_incl_ms_food &&
																touched.percent_incl_ms_food) ||
															messageErro !== ''
														}
													/>
													{messageErro !== '' ? (
														<Error>{messageErro}</Error>
													) : null}
												</Grid>

												<Grid item sm="auto" xs="auto">
													<StyledButton
														variant="contained"
														color={colors.white}
														background={colors.success}
														onClick={handleSendFoodToList}
													>
														Adicionar
													</StyledButton>
												</Grid>
											</Can>
										</StyledGridFood>
										<TableContainer>
											<StyledTable stickyHeader>
												<StyledTableHead>
													<TableRow>
														<Can
															checkRole={['administrator', 'owner', 'beefer']}
														>
															<TableCell padding="checkbox">
																{!hasSameFood() && (
																	<Checkbox
																		indeterminate={
																			countFoods > 0 &&
																			countFoods < listFood.length
																		}
																		checked={countFoods === listFood.length}
																		onChange={handleSelectAllFoods}
																		color="primary"
																		inputProps={{
																			'aria-label': 'Selecionar todos',
																		}}
																	/>
																)}
															</TableCell>
														</Can>
														<TableCell align="left">Alimento</TableCell>
														<TableCell align="right">MS (%)</TableCell>
														<TableCell align="right">Incl. MS (%)</TableCell>
														<TableCell align="right">Incl. MO (%)</TableCell>
														<Can
															checkRole={['administrator', 'owner', 'beefer']}
														>
															<TableCell />
														</Can>
													</TableRow>
												</StyledTableHead>
												<TableBody>
													{listFood.map((food, index) => (
														<TableRow key={food.unique_key}>
															<Can
																checkRole={[
																	'administrator',
																	'owner',
																	'beefer',
																]}
															>
																<TableCell padding="checkbox">
																	<Checkbox
																		color="primary"
																		inputProps={{
																			'aria-labelledby': food.unique_key,
																		}}
																		aria-checked={food.selected}
																		checked={food.selected}
																		disabled={hasFoodSelected(food)}
																		onClick={() =>
																			handleSelectedFood(food, index)
																		}
																	/>
																</TableCell>
															</Can>
															<TableCell component="th" scope="row">
																{food.preMixes && food.preMixes.length > 0 ? (
																	<StyledButtonMix
																		onClick={() => handleModalMixFoods(food)}
																	>
																		{food.name}
																	</StyledButtonMix>
																) : (
																	<FoodName>
																		<span>{food.name}</span>
																	</FoodName>
																)}
															</TableCell>
															<TableCell align="right">
																{`${formatNumber(food.percent_ms, 2, 2)} %`}
															</TableCell>
															<TableCell align="right">
																{`${formatNumber(
																	food.dietFoodPivot.percent_incl_ms_food,
																	4,
																	4
																)} %`}
															</TableCell>
															<TableCell align="right">
																{`${formatNumber(
																	food.dietFoodPivot.percent_incl_mo_food,
																	4,
																	4
																)} %`}
															</TableCell>
															<Can
																checkRole={[
																	'administrator',
																	'owner',
																	'beefer',
																]}
															>
																<TableCell>
																	<ButtonTable
																		icon={<DeleteIcon color="error" />}
																		background={colors.error}
																		onClick={() =>
																			handleRemoveFoodToList(index)
																		}
																	/>
																	<ButtonsChangeOrder setListFood={setListFood} order={food.dietFoodPivot.order} maxOrder={listFood.length - 1} />
																</TableCell>
															</Can>
														</TableRow>
													))}

													<TableRow>
														<StyledTableCell />
														<TableCell component="th" scope="row" align="left">
															<TitleTable>Total</TitleTable>
														</TableCell>
														<Can
															checkRole={['administrator', 'owner', 'beefer']}
														>
															<TableCell />
														</Can>
														<TableCell align="right">
															{`${formatNumber(totalInclMS, 4, 4)} %`}
														</TableCell>
														<TableCell align="right">
															{`${formatNumber(totalInclMO, 4, 4)} %`}
														</TableCell>
														<Can
															checkRole={['administrator', 'owner', 'beefer']}
														>
															<TableCell />
														</Can>
													</TableRow>
													<TableRow>
														<Can
															checkRole={['administrator', 'owner', 'beefer']}
														>
															<StyledTableCell
																colSpan={1}
															/>
														</Can>
														{lackOfTotal >= 0 ||
															(lackOfTotal < 100 && (
																<>
																	<TableCell align="left" colSpan={1}>
																		<TitleTable>% Incompleto</TitleTable>
																	</TableCell>
																	<TableCellStyled align="right" size="small">
																		{`${formatNumber(lackOfTotal, 4, 4)} %`}
																	</TableCellStyled>
																</>
															))}
													</TableRow>

													<TableRow>
														<Can
															checkRole={['administrator', 'owner', 'beefer']}
														>
															<StyledTableCell
																colSpan={2}
															/>
														</Can>
														<TableCell align="left" colSpan={2}>
															<TitleTable>% MS na dieta</TitleTable>
														</TableCell>
														<TableCell align="right" colSpan={2}>
															{`${formatNumber(percentMSDiet, 2, 2)} %`}
														</TableCell>
													</TableRow>
												</TableBody>
											</StyledTable>
										</TableContainer>

										<ContentSubmitButtons>
											<Can checkRole={['administrator', 'owner', 'beefer']}>
												<StyledButtonMixer
													variant="contained"
													color="primary"
													disabled={countFoods === 0}
													onClick={handleModalMix}
												>
													<AutorenewIcon />
													Pré Mistura
												</StyledButtonMixer>
											</Can>

											<div>
												<StyledSubmitButton
													variant="outlined"
													color="primary"
													marginLeft={10}
													onClick={handleBack}
												>
													{routeName !== 'view' ? 'Cancelar' : 'Voltar'}
												</StyledSubmitButton>
												<ButtonSubmit
													title="Cadastrar"
													loading={isSubmitting}
													disabled={lackOfTotal !== 0 || isSubmitting}
												/>
											</div>
										</ContentSubmitButtons>
									</StyledPaper>
								</ContentItem>
							</Form>
						)}
					</Formik>

					{visibleModalFood && (
						<ModalFood
							visibleModal={visibleModalFood}
							handleModal={handleModalFood}
						/>
					)}

					{visibleModalMix && (
						<ModalMix
							visibleModal={visibleModalMix}
							handleModal={handleModalMix}
							handleGenerateMix={handleGenerateMix}
							errorName={messageErroFood}
						/>
					)}

					{visibleModalMixFoods && (
						<Can checkRole={['administrator', 'owner', 'beefer']}>
							<ModalMixFoods
								visibleModal={visibleModalMixFoods}
								handleModal={handleModalMixFoods}
								mix={currentMixFood}
								handleRollbackMix={handleRollbackMix}
								enableReturnMix
							/>
						</Can>
					)}
				</Container>
			)}
		</center>
	);
}

Register.propTypes = {
	closeModal: PropTypes.func,
};
