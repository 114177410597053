import React, { useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
} from '@material-ui/core';
import Filter from './Components/Filter';

import { useApiV2Animal } from '~/hooks/apiV2/register/animal/useApiV2Animal'

import {
  Container,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
  StyledGrid,
  ContentButton
} from './styles';

import Can from '~/components/Can';
import Loader from '~/components/Loader';
import { useAuthContext } from '~/contexts/AuthContext';
import { CardTableAnimal } from './Components/CardTableAnimal';
import history from '~/services/history';
import ButtonEdit from '~/components/ButtonEdit';

export default function Animals() {
  const { state: location } = useLocation();

  const lot_id = useMemo(
    () => (location && location.lot_id ? location.lot_id : null),
    [location]
  );

  const { farm } = useAuthContext();
  const { powerUps } = farm;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [breedSecureId, setBreedSecureId] = useState('');
  const [lotSecureId, setLotSecureId] = useState('');
  const [search, setSearch] = useState('');
  const [identificationType, setIdentificationType] = useState('');

  const [field, setField] = useState('breed_name');
  const [direction, setDirection] = useState('asc');

  const { data, isLoading } = useApiV2Animal(
    `${field}%7C${direction}`,
    page + 1,
    rowsPerPage,
    breedSecureId,
    lotSecureId,
    identificationType,
    search
  );

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangeOrder(fieldOrder) {
    if (field === fieldOrder) {
      if (direction === 'asc') {
        setDirection('desc');
      } else {
        setDirection('asc');
      }
    } else {
      setField(fieldOrder);
      setDirection('asc');
    }
  }

  function handleEditAnimals() {
    history.push('/dashboard/animals/editInLot', { lot_id: lotSecureId });
  }

  return (
    <center>
      <Container>
        <StyledGrid container alignItems="center" justify="space-between" spacing={2}>
          <Grid item xs sm md lg xl>
            <Filter
              lot_id={lot_id}
              setBreedSecureId={setBreedSecureId}
              setLotSecureId={setLotSecureId}
              setSearch={setSearch}
              setPage={setPage}
              identificationType={identificationType}
              setIdentificationType={setIdentificationType}
            />
          </Grid>
          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['animals_edit']}
          >
            <Grid item xs="auto" sm="auto">
              <ContentButton>
                <ButtonEdit title="Editar em lote" onClick={handleEditAnimals} />
              </ContentButton>
            </Grid>
          </Can>
        </StyledGrid>
        {isLoading ? (
          <Loader />
        ) : (
          <StyledPaper>
            <StyledTableContainer>
              <Table stickyHeader>
                <StyledTableHead>
                  <TableRow>
                    {!powerUps || powerUps.length <= 0 ? (
                      <>
                        <TableCell align="left">
                          Sisbov
                        </TableCell>
                        <TableCell align="left">
                          Bottom RFID
                        </TableCell>
                        <TableCell align="left">
                          Brinco
                        </TableCell>
                      </>
                    ) : (
                      <TableCell align="left">
                        Identificador do animal
                      </TableCell>
                    )}
                    <TableCell align="left">
                      <TableSortLabel
                        active={field === 'breed_name'}
                        direction={field === 'breed_name' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('breed_name')}
                      >
                        Raça
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left">
                      Lote/Linha-Piquete
                    </TableCell>
                    <TableCell align="left">
                      <TableSortLabel
                        active={field === 'active'}
                        direction={field === 'active' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('active')}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    <Can checkRole={['administrator', 'owner', 'manager']}>
                      <TableCell align="right" />
                    </Can>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {data && data.animals.map(animal => (
                    <CardTableAnimal
                      key={animal.secure_id}
                      animal={animal}
                      setIdentificationType={setIdentificationType}
                      lot_id={lotSecureId}
                    />
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              labelRowsPerPage="Linhas por pág."
              component="div"
              count={data ? data.total : 0}
              rowsPerPage={data ? data.perPage : 0}
              page={data ? data.page - 1 : 0}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </StyledPaper>
        )}
      </Container>
    </center>
  );
}
